import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import Button from '@/components/button/Button.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import { productMessageTypeList } from '@/domain/value-objects/MessageTypes'

@Component({
  components: {
    Sidebar,
    Header,
    Button
  }
})

export default class TriggerIndex extends Vue {
  private searchString = ''

  get messageTypes (): any[] {
    return Object.keys(productMessageTypeList[this.$store.state.product]).filter((messageType) => {
      return messageType.toLowerCase().includes(this.searchString.toLowerCase())
    }).map((messageType) => {
      return {
        name: messageType,
        value: productMessageTypeList[this.$store.state.product][messageType].value
      }
    })
  }
}
