import { Products } from './Products'

export type MessageTypeIdentificatorType = 'after_pay2'
  | 'cancellation'
  | 'confirm_email_nopass'
  | 'flirt'
  | 'forgotpass'
  | 'friend_accepted'
  | 'new_message'
  | 'photo_removed'
  | 'temporary_access'
  | 'user_deleted'
  | 'friend'
  | 'dating_user_registered';

type MessageTypeType = {
  value: MessageTypeIdentificatorType;
  placeholders: Array<string>;
  hiddenFields: {
    trustabilityLevel: number;
    useInterval: boolean;
    sender: string;
  };
}

type BasicMessageTypeListType = { [key: string]: MessageTypeType }

type ProductMessageTypeListType = { [key: string]: BasicMessageTypeListType }

export const basicMessageTypeList: BasicMessageTypeListType = {
  AfterPay: {
    value: 'after_pay2',
    placeholders: ['{{unsubscribe}}', '{{link}}', '{{email_settings}}', '{{support_site}}', '{{support_phone}}', '{{email}}', '{{user_id}}', '{{transaction_id}}'],
    hiddenFields: {
      trustabilityLevel: 0.5,
      useInterval: false,
      sender: ''
    }
  },
  Cancellation: {
    value: 'cancellation',
    placeholders: ['{{email_settings}}', '{{unsubscribe}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: false,
      sender: ''
    }
  },
  ConfirmEmailNopass: {
    value: 'confirm_email_nopass',
    placeholders: ['{{username}}', '{{link}}', '{{email}}', '{{email_settings}}', '{{unsubscribe}}'],
    hiddenFields: {
      trustabilityLevel: 0.5,
      useInterval: false,
      sender: ''
    }
  },
  Flirt: {
    value: 'flirt',
    placeholders: ['{{unsubscribe}}', '{{username}}', '{{sender_username}}', '{{sender_picture}}', '{{sender_location}}', '{{date}}', '{{link}}', '{{email_settings}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  },
  ForgotPass: {
    value: 'forgotpass',
    placeholders: ['{{unsubscribe}}', '{{username}}', '{{password}}', '{{link}}', '{{email_settings}}'],
    hiddenFields: {
      trustabilityLevel: 0.5,
      useInterval: false,
      sender: ''
    }
  },
  FriendAccepted: {
    value: 'friend_accepted',
    placeholders: ['{{unsubscribe}}', '{{username}}', '{{sender_username}}', '{{sender_picture}}', '{{sender_location}}', '{{date}}', '{{link}}', '{{email_settings}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  },
  NewMessage: {
    value: 'new_message',
    placeholders: ['{{unsubscribe}}', '{{username}}', '{{sender_username}}', '{{sender_picture}}', '{{sender_location}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  },
  PhotoRemoved: {
    value: 'photo_removed',
    placeholders: ['{{unsubscribe}}', '{{username}}', '{{user_settings}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  },
  TemporaryAccess: {
    value: 'temporary_access',
    placeholders: ['{{link}}', '{{username}}', '{{email_settings}}', '{{unsubscribe}}'],
    hiddenFields: {
      trustabilityLevel: 0.5,
      useInterval: false,
      sender: ''
    }
  },
  UserDeleted: {
    value: 'user_deleted',
    placeholders: ['{{link}}', '{{username}}', '{{email_settings}}', '{{unsubscribe}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  },
  Friend: {
    value: 'friend',
    placeholders: ['{{unsubscribe}}', '{{user_settings}}', '{{link}}', '{{date}}', '{{sender_location}}', '{{sender_username}}', '{{sender_picture}}'],
    hiddenFields: {
      trustabilityLevel: 1,
      useInterval: true,
      sender: ''
    }
  }
}

export const qkkieMessageTypeList: BasicMessageTypeListType = {
  DatingUserRegistered: {
    value: 'dating_user_registered',
    placeholders: ['{{domain}}', '{{username}}', '{{email}}', '{{autologinUrl}}', '{{siteColor}}', '{{bgColor}}', '{{logoUrl}}', '{{logoWidth}}'],
    hiddenFields: {
      trustabilityLevel: 0,
      useInterval: false,
      sender: ''
    }
  }
}

export const productMessageTypeList: ProductMessageTypeListType = Object.keys(Products).reduce(
  (productMessageTypeListAcc: ProductMessageTypeListType, productName: string) => {
    let completedMessageTypeList = null
    if (productName === 'Qkkie') {
      completedMessageTypeList = Object.keys(qkkieMessageTypeList).reduce(
        (messageTypeListAcc: BasicMessageTypeListType, messageTypeName: string) => {
          const messageType = { ...qkkieMessageTypeList[messageTypeName] }
          messageType.hiddenFields = { ...qkkieMessageTypeList[messageTypeName].hiddenFields }
          messageType.hiddenFields.sender = Products[productName].senders[messageTypeName]
          messageTypeListAcc[messageTypeName] = messageType
          return messageTypeListAcc
        },
        {}
      )
    } else {
      completedMessageTypeList = Object.keys(basicMessageTypeList).reduce(
        (messageTypeListAcc: BasicMessageTypeListType, messageTypeName: string) => {
          const messageType = { ...basicMessageTypeList[messageTypeName] }
          messageType.hiddenFields = { ...basicMessageTypeList[messageTypeName].hiddenFields }
          messageType.hiddenFields.sender = Products[productName].senders[messageTypeName]
          messageTypeListAcc[messageTypeName] = messageType
          return messageTypeListAcc
        },
        {}
      )
    }

    productMessageTypeListAcc[productName] = completedMessageTypeList
    return productMessageTypeListAcc
  },
  {}
)
